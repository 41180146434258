/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.absolute-fill {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  height: 100% !important;
  width: 100% !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.dialog-header {
  display: flex;
  align-items: center;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background: green !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
  background: green !important;
}

.logo-as-title-login {
  margin-top: -10px;

  span {
    padding-bottom: 4px;
    position: relative;

    &::before {
      position: absolute;
      display: block;
      bottom: -26.8px;
      left: 18.4px;
      font-size: 8px;
    }

    img {
      height: 72px;
      width: 72px;
    }
  }
}
